import React, { PureComponent } from 'react'
import styled from 'styled-components'
import { Grid } from '@material-ui/core'

import Layout from '../components/Layout'
import SEO from '../components/seo'

const SuccessContainer = styled.div`
  margin: auto;
  max-width: 1200px;
  padding: 50px;

  h2 {
    margin: 0 0 10px 0;
  }
`

class Success extends PureComponent {
  render() {
    return (
      <Layout>
        <SEO title="Success" keywords={['contact', 'success']} />
        <SuccessContainer>
          <Grid container>
            <Grid item xs={12}>
              <h2>Message Received</h2>
              <p>
                Thanks for contacting us. We'll get back to you as soon as we
                can.
              </p>
            </Grid>
          </Grid>
        </SuccessContainer>
      </Layout>
    )
  }
}

export default Success
